import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';
import mixinTheme from '@/components/mixins/mixinTheme';

const { VUE_APP_GOOGLE_PHONE_NUMBER, VUE_APP_FACEBOOK_PHONE_NUMBER } = process.env;

export default {
  name: 'TopHeader',
  mixins: [imageUrlMixin, mixinTheme],
  computed: {
    ...mapGetters({
      homeLink: 'GET_HOME_LINK',
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      device: 'GET_DEVICE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      landingInfo: 'GET_LANDING_INFO',
      landingDealData: 'GET_LANDING_DEAL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      agencyUser: 'GET_AGENCY_USER',
      user: 'GET_MEMBER',
      typeChannel: 'GET_TYPE_CHANNEL',
      currentPage: 'GET_CURRENT_PAGE',
      odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
      agencyListForPricePlan: 'GET_AGENT_LIST_PRICE_PLAN_ONLY',
      bannerTimerData: 'GET_BANNER_TIMER',
      isMarketerSite: 'GET_IS_MARKETER_SITE',
      enableSearchAgentState: 'GET_ENABLE_SEARCH_AGENT_STATE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',

      isRemoveSocialIcon: 'GET_IS_REMOVE_SOCIAL_ICON',
      isRemoveHomeButton: 'GET_IS_REMOVE_HOME_BUTTON',
      isRemovePhoneButton: 'GET_IS_REMOVE_PHONE_BUTTON',
      isTargetAgency: 'GET_IS_TARGET_AGENCY',
      isByteAgency: 'GET_IS_BYTE_AGENCY',
    }),
    phoneDescription() {
      return this.marketerAgencyContent.phoneDescription || '';
    },
    timerData() {
      const { bannerTimerData, isMarketerSite } = this;
      if (!bannerTimerData) return null;
      return bannerTimerData?.filter((tim) => tim.active && (!isMarketerSite || tim.showInMarketerSites))[0] || null;
    },
    isShowBannerTimer() {
      const { timerStart, timerEnd } = this;
      if (!timerStart || !timerEnd) return false;
      return new Date() > new Date(timerStart) && new Date() < new Date(timerEnd);
    },
    isWithTimer() {
      const { timerData } = this;
      if (!timerData) return null;
      return timerData.withTimer;
    },
    timerStart() {
      const { timerData } = this;
      if (!timerData) return null;
      return timerData.timerStart;
    },
    timerEnd() {
      const { timerData } = this;
      if (!timerData) return null;
      return timerData.timerEnd;
    },
    timerText() {
      const { timerData } = this;
      if (!timerData) return null;
      return timerData.text;
    },
    timerColor() {
      const { timerData } = this;
      if (!timerData) return null;
      return timerData.color.toLowerCase().replaceAll(' ', '-');
    },
    timerSubject() {
      const { timerData } = this;
      if (!timerData) return null;
      return timerData.campaignSubject;
    },
    showOrgSearchTab() {
      const { destination, areaType } = this.$route.query;
      return destination === 'EXTRA100ORG' && areaType === 'true';
    },
    phoneNumber() {
      if (this.showOrgSearchTab) return '0723954377';
      const utmSource = this.$route.query.utm_source;
      const phone = utmSource === 'google' ? VUE_APP_GOOGLE_PHONE_NUMBER : VUE_APP_FACEBOOK_PHONE_NUMBER;
      return utmSource ? phone : this.hostData?.phoneNumber || this.whiteLabel.phoneNumber;
    },
    isFooterPage() {
      return this.currentPage === 'footer';
    },
    isNotHomePage() {
      // return this.isScroll && this.currentPage !== 'home';
      return this.currentPage !== 'home';
    },
    logoURL() {
      return this.homeLink;
    },
    showFacebookLink() {
      return !this.isAgencySite || !!this.hostData?.facebookLink;
    },
    facebookLink() {
      const defaultLink = 'https://he-il.facebook.com/flying.co.il/';
      const link = this.hostData?.facebookLink ? `https://www.facebook.com/${this.hostData?.facebookLink.replace('https://www.facebook.com/', '')}` : defaultLink;
      return link;
    },
    showInstagramLink() {
      return !this.isAgencySite || !!this.hostData?.instagramLink;
    },
    instagramLink() {
      const defaultLink = 'https://www.instagram.com/flyingcarpet_il/';
      const link = this.hostData?.instagramLink ? `https://www.instagram.com/${this.hostData?.instagramLink.replace('https://www.instagram.com/', '')}` : defaultLink;
      return link;
    },
    showTwitterLink() {
      return !this.isAgencySite || !!this.hostData?.twitterLink;
    },
    twitterLink() {
      const defaultLink = 'https://twitter.com/@flyingcarpetltd';
      const link = this.hostData?.twitterLink ? `https://twitter.com/${this.hostData?.twitterLink.replace('https://twitter.com/', '')}` : defaultLink;
      return link;
    },
    showVacationPhone() {
      return !this.isAgencySite || (this.isAgencySite && this.phoneNumber !== this.whiteLabel.phoneNumber);
    },
    vacationPhoneNumber() {
      return this.whiteLabel.phoneNumber;
    },
    showWhatsappNumber() {
      return this.whatsappNumber !== '';
    },
    whatsappNumber() {
      return this.hostData?.whatsappAddress ?? '';
    },
    marketerLogo() {
      const { marketerAgencyContent } = this;
      return marketerAgencyContent?.logoUrls?.[0] ?? '';
    },
    srcLogo() {
      return this.marketerLogo || this.whiteLabel.logoUrls[0];
    },
  },
  watch: {
    lang() {
      this.changeRtl();
    },
  },
  data() {
    return {
      rtlClassName: '',
      landingQuery: '',
      isScroll: false,
      destinationName: '',
      isTimerFinished: false,
    };
  },
  async mounted() {
    this.changeRtl();
    this.landingQuery = window.location.search;
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
    if (this.isLanding && this.landingDealData && this.landingDealData.length > 0) {
      this.destinationName = this.landingDealData[0].destinationName;
    }
    if (this.isFcAgentMarketerMode) {
      await this.$store.dispatch('FETCH_AGENT_LIST_PRICE_PLAN_ONLY');
    }
  },
  methods: {
    changeRtl() {
      if (this.lang === 'he') {
        this.rtlClassName = 'phone-number-rtl';
      } else {
        this.rtlClassName = '';
      }
    },
    onScroll(event) {
      this.isScroll = Number(event.target.scrollTop) > 150;
    },
    timerStarted() {
      this.isTimerFinished = false;
    },
    timerFinished() {
      this.isTimerFinished = true;
    },
    showAgentListModal() {
      this.$bvModal.show('agentListForPricePlan');
    },
    async logout() {
      this.$bvModal.msgBoxConfirm(this.$t('login.log-out-confirm-box-title'), {
        title: this.$t('login.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('login.yes'),
        cancelTitle: this.$t('login.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async (value) => {
          if (value) await this.$store.dispatch('REQUEST_LOGOUT');
        });
    },
    async agencyLogout() {
      this.$bvModal.msgBoxConfirm(this.$t('login.log-out-confirm-box-title'), {
        title: this.$t('login.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('login.yes'),
        cancelTitle: this.$t('login.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async (value) => {
          if (value) await this.$store.dispatch('REQUEST_AGENCY_LOGOUT');
          if (this.isMaccabiAgency && this.$route.fullPath !== this.homeLink) {
            this.$router.push(this.homeLink);
          }
        });
    },
  },
};
